import React from "react";
import { AUTH_TOKEN } from "utils/constants";
import {
  Environment,
  Network,
  RecordSource,
  Store,
  fetchQuery as fQ,
} from "relay-runtime";
import { commitMutation, QueryRenderer as QR } from "react-relay";

import Loader from "components/Loader";
import Config from "Config";
import moment from "moment";

function networkPost(endpoint, variables) {
  const authToken = window.localStorage.getItem(AUTH_TOKEN);
  let headers = { "Content-Type": 'text/csv' };
  if (authToken) {
    headers["Authorization"] = "JWT " + authToken;
  }
  return fetch(`${Config.BASE_URL}/${endpoint}`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      variables
    }),
    responseType: 'blob'
  }).then((response) => response.blob())
  .then((success) => {
    const url = window.URL.createObjectURL(success);
    const a = document.createElement('a');
    a.href = url;
    a.download = `InventoryForecast${moment(new Date()).format("DD-MM-YYYY")}.csv`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  })
}
function fetchQueryStartLoading(
  operation,
  variables,
) {
  const authToken = window.localStorage.getItem(AUTH_TOKEN);
  let headers = { "Content-Type": "application/json" };
  if (authToken) {
    headers["Authorization"] = "JWT " + authToken;
  }
  return fetch(`${Config.BASE_URL}/graphql`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  }).then(response => {
    return response.json();
  });
}

const RelayEnv = new Environment({
  network: Network.create(fetchQueryStartLoading),
  store: new Store(new RecordSource()),
});

function network(operation, variables, cacheConfig, uploadables) {
  const authToken = window.localStorage.getItem(AUTH_TOKEN);
  let request = {};
  if (authToken) {
    request = {
      method: "POST",
      headers: {
        Authorization: `JWT ${authToken}`,
      },
    };
  } else {
    request = {
      method: "POST",
      headers: { "Content-Type": "application/json" }
    };
  }
  if (uploadables) {
    if (!window.FormData) {
      throw new Error("Uploading files without `FormData` not supported.");
    }
    const formData = new FormData();
    formData.append("query", operation.text);
    formData.append("variables", JSON.stringify(variables));
    Object.keys(uploadables).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
        formData.append(key, uploadables[key]);
      }
    });
    request.body = formData;
  } else {
    request.headers["Content-Type"] = "application/json";
    request.body = JSON.stringify({
      query: operation.text,
      variables,
    });
  }

  return fetch(`${Config.BASE_URL}/graphql`, request).then((response) => {
    return response.json();
  });
  // .then((response) => {
  //   if (response.status === 200) {
  //     return response.json();
  //   }
  //   return response.json();
  // })
  // .catch((error) => {
  // });
}

const environment = new Environment({
  network: Network.create(network),
  store: new Store(new RecordSource()),
});

function mutateBase({
  mutation,
  variables,
  onSuccess,
  onFailure,
  uploadables,
}) {
  const onCompleted = (response, errors) => {
    if (errors && errors.length > 0) {
      var messages = [];
      errors.forEach((error) => {
        messages.push(error.message);
      });
      messages = messages.join("; ");
      if (onFailure) {
        return onFailure(messages);
      } else {
        return alert(messages);
      }
    }
    return onSuccess(response);
  };
  return commitMutation(environment, {
    mutation,
    variables,
    uploadables,
    onCompleted,
  });
}

function r2mutateBase({
  mutation,
  variables,
  onSuccess,
  onFailure,
  uploadables,
}) {
  const onCompleted = (response, errors) => {
    if (errors && errors.length > 0) {
      var messages = [];
      errors.forEach((error) => {
        messages.push(error.message);
      });
      messages = messages.join("; ");
      if (onFailure) {
        return onFailure(messages);
      } else {
        return alert(messages);
      }
    }
    return onSuccess(response);
  };
  return commitMutation(environment, {
    mutation,
    variables,
    uploadables,
    onCompleted,
  });
}

function mutate({ mutation, input, onSuccess, onFailure, uploadables }) {
  const variables = { input };
  return mutateBase({ mutation, variables, onSuccess, onFailure, uploadables });
}

function r2mutate({ mutation,id, input, onSuccess, onFailure, uploadables }) {
  const variables =  {id:id, input};
  return r2mutateBase({ mutation, variables, onSuccess, onFailure, uploadables });
}

function r2mutate2({ mutation, input, onSuccess, onFailure, uploadables }) {
  const variables =  input;
  return r2mutateBase({ mutation, variables, onSuccess, onFailure, uploadables });
}


function QueryRenderer(_props) {
  const onSuccess = _props.onSuccess;
  return (
    <QR
      environment={environment}
      render={({ error, props }) => {
        if (error) {
          return <div> {error.message} </div>;
        }
        if (props && Object.keys(props).length > 0) {
          return onSuccess(props);
        }
        if (_props.isSelect) {
          return <option>Loading...</option>;
        } else {
          return <Loader />;
        }
      }}
      {..._props}
    />
  );
}

function fetchQuery(query, variables = {}) {
  return fQ(environment, query, variables);
}

export { QueryRenderer, mutate, fetchQuery, environment, mutateBase, RelayEnv,r2mutate,r2mutate2,networkPost };

