const PROD = {
  BASE_URL: "https://apibeta.d8adriven.io",
  APP_URL: "https://betaapp.d8adriven.io",
  AMS_URL: "https://www.amazon.com/ap/oa?client_id=amzn1.application-oa2-client.a2ecdc9149254e518c29d5be87143d25&scope=cpc_advertising:campaign_management&response_type=code&redirect_uri=https://betaapp.d8adriven.io/auth/authorization"
};

const BETA = {
  BASE_URL: "https://apibeta.d8adriven.io",
  APP_URL: "https://betaapp.d8adriven.io",
  AMS_URL: "https://www.amazon.com/ap/oa?client_id=amzn1.application-oa2-client.a2ecdc9149254e518c29d5be87143d25&scope=cpc_advertising:campaign_management&response_type=code&redirect_uri=https://betaapp.d8adriven.io/auth/authorization"
};

const DEV = {
  BASE_URL: "http://localhost:8000",
  APP_URL: "http://localhost:3000",
  AMS_URL: "https://www.amazon.com/ap/oa?client_id=amzn1.application-oa2-client.a2ecdc9149254e518c29d5be87143d25&scope=cpc_advertising:campaign_management&response_type=code&redirect_uri=https://betaapp.d8adriven.io/auth/authorization"
};





const MAP = {
  production: PROD,
  beta: BETA,
  development: DEV
};

const Config = MAP[process.env.NODE_ENV];

export default Config;
