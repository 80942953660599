import { action } from "easy-peasy";

const MiscStore = {
  sidebarCollapse: false,
  companies: [],
  activeCompanyId: null,
  oldId: null,
  activeCompanyType: null,
  isLoader: false,
  tier: "",
  showWizard: true,
  showFRE: "",
  notificationCount: 0,
  dataAcquisition: false,
  currentFunctionalAreaId:"",

  setShowLoader: action((state) => {
    state.isLoader = true;
  }),
  setShowWizard: action((state, payload) => {
    state.showWizard = payload;
  }),
  setShowFRE: action((state, payload) => {
    state.showFRE = payload;
  }),
  setNotificationCount: action((state, payload) => {
    state.notificationCount = payload;
  }),
  setDataAcquisition: action((state, payload) => {
    state.dataAcquisition = payload;
  }),
  setHideLoader: action((state) => {
    state.isLoader = false;
  }),
  toggleLoader: action((state) => {
    state.isLoader = !state.isLoader;
  }),
  setcurrentFunctionalAreaId:action((state,payload)=>{
    state.currentFunctionalAreaId =payload
  }),
  setSidebarCollapse: action((state, sidebarCollapse) => {
    state.sidebarCollapse = sidebarCollapse;
  }),

  setCurrentCompanies: action((state, companies) => {
    state.companies = companies;
  }),

  addCompany: action((state, company) => {
    state.companies.push(company)
  }),

  updateCompany: action((state, payload) => {
    const index = state.companies.findIndex(item => item.id === payload.id)
    state.companies[index] = payload
  }),

  setActiveCompanyId: action((state, activeCompanyId) => {
    state.activeCompanyId = activeCompanyId;
  }),

  setOldId: action((state, oldId) => {
    state.oldId = oldId;
  }),

  setActiveCompanyType: action((state, activeCompanyType) => {
    state.activeCompanyType = activeCompanyType
  }),

  setTier: action((state, tier) => {
    state.tier = tier;
  }),
};

export default MiscStore;