import { thunk, action } from "easy-peasy";

import { fetchQuery } from "utils/relay";
import { emptyProxyObject, isEmpty } from "utils/utils";

const ItemStore = {
  item: emptyProxyObject,
  items: [],

  clearItem: action(state => {
    state.item = emptyProxyObject;
  }),

  clearItems: action(state => {
    state.items = [];
  }),

  setItem: action((state, item) => {
    state.item = item;
  }),

  setItems: action((state, items) => {
    state.items = items;
  }),

  addItem: action((state, item) => {
    state.items.push(item)
  }),

  updateItem: action((state, payload) => {
    const index = state.items.findIndex(item => item.id === payload.id)
    state.items[index] = payload
  }),

  updatePortfolio: action((state, payload) => {
    const index = state.items.findIndex(item => item.channelPortfolioId === payload.channelPortfolioId)
    state.items[index] = payload
  }),

  updateCampaign: action((state, payload) => {
    const index = state.items.findIndex(item => item.channelCampaignId === payload.channelCampaignId)
    state.items[index] = payload
  }),

  updateWeekly: action((state, payload) => {
    const index = state.items.findIndex(item => item.company.id === payload.company.id)
    state.items[index] = payload
  }),

  updatePo: action((state, payload) => {
    state.items = payload
  }),

  updateUser: action((state, { id, data }) => {
    const index = state.items.findIndex(item => item.id === id)
    state.items[index] = data
  }),

  updateInput: action((state, payload) => {
    state.items = payload
  }),

  removeItem: action((state, payload) => {
    const index = state.items.findIndex(item => item.id === payload.id)
    state.items.splice(index, 1)
  }),

  fetchItem: thunk((actions, { query, variables }) => {
    actions.clearItem();
    return fetchQuery(query, variables).then(itemWrapper => {
      const key = Object.keys(itemWrapper)[0];
      actions.setItem(itemWrapper[key]);
    });
  }),

  fetchItems: thunk((actions, { query, variables }) => {
    actions.clearItems();
    return fetchQuery(query, variables).then(itemsWrapper => {
      const key = Object.keys(itemsWrapper)[0];
      const data = itemsWrapper[key] ? itemsWrapper[key] : []
      actions.setItems(data);
    });
  }),

  fetchItemsCustom: thunk((actions, { query, variables }) => {
    actions.clearItems();
    return fetchQuery(query, variables).then(itemsWrapper => {
      const key = Object.keys(itemsWrapper)[0];
      const data = itemsWrapper[key] ? itemsWrapper[key] : []
      const parsedData = !isEmpty(data) ? JSON.parse(data) : []
      const product = !isEmpty(parsedData) ? parsedData.product_label : []
      actions.setItems(product);
    });
  }),

  fetchItemsManageAsin: thunk((actions, { query, variables }) => {
    actions.clearItems();
    return fetchQuery(query, variables).then(itemsWrapper => {
      const key = Object.keys(itemsWrapper)[0];
      const data = itemsWrapper[key] ? itemsWrapper[key] : []
      const parsedData = !isEmpty(data) ? JSON.parse(data) : []
      const product = !isEmpty(parsedData) ? parsedData.product_list : []
      actions.setItems(product);
    });
  })
};

export default ItemStore;
