import moment from "moment";

const emptyProxyObject = new Proxy({}, { get: () => "" });

const isEmpty = (val) => {
  // Stolen From: https://stackoverflow.com/a/28953167
  /*
    test results
    --------------
    [] true, empty array
    {} true, empty object
    null true
    undefined true
    "" true, empty string
    '' true, empty string
    0 false, number
    true false, boolean
    false false, boolean
    Date false
    function false
    */
  if (val === undefined) return true;
  if (val === emptyProxyObject) return true;

  if (
    typeof val == "function" ||
    typeof val == "number" ||
    typeof val == "boolean" ||
    Object.prototype.toString.call(val) === "[object Date]"
  )
    return false;

  if (val == null || val.length === 0)
    // null or 0 length array
    return true;

  if (typeof val == "object") if (Object.keys(val).length === 0) return true;

  return false;
};

const createrange = function (start, end, isEndInclude, step) {
  let arr = [],
    len = 0;

  step = step === undefined ? 1 : step;

  if (arguments.length === 1) {
    len = start;
    start = 0;
    end = start;
  } else {
    start = start === undefined ? 1 : start;
    end = end === undefined ? 1 : end;
    len = end - start;
  }

  var i = 0;
  while (i <= len) {
    arr.push(start + i * step);

    i += 1;
  }

  return arr;
};

const getNameInitial = (text) => {
  const name = (text === "" || text === null) ? "NA" : text.split(" ")
  const [firstName, lastName] = name
  if (firstName && lastName) {
    return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`
  } else {
    return `${firstName[0].toUpperCase()}`
  }
}

const formatNumber = inputNumber => {
  return inputNumber.toLocaleString(undefined, { maximumFractionDigits: 2 })
};

const kFormatter = num => {
  return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(2)) + 'k' : Math.sign(num) * Math.abs(num)
}

const startOfWeek = (year, week) => {
  const d = new Date(`01-01-${year}`)
  return moment(d)
    .add(week, "weeks")
    .startOf("week").format("MMM-DD");
};

const endOfWeek = (year, week) => {
  const d = new Date(`01-01-${year}`)
  return moment(d)
    .add(week , "weeks")
    .endOf("week").format("MMM-DD");
};

export { isEmpty, emptyProxyObject, createrange, getNameInitial, formatNumber, kFormatter, startOfWeek, endOfWeek };
