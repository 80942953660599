import React from "react";

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader-text">
        <img className="d8LoaderLogo" alt="" src={"/img/brand-logo.svg"} />
      </div>
    </div>
  );
};

export default Loader;
